import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import Home from './components/home'

function App() {
  const [ratio, setRatio] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  })
  const [sHeight, setSHeight] = useState("100vh")

  function refreshRatio() {
    setRatio({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })
    setSHeight("100vh")
    setTimeout(() => {
      setSHeight(document.body.scrollHeight)
    }, 500);
  }



  useEffect(() => {
    window.addEventListener('resize', refreshRatio)
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route>
            <Route exact path="/">
              <Home ratio={ratio}/>
            </Route>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
