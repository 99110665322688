import bg1 from '../images/Boreas1.jpg'
import arrow from '../icons/down-arrow.svg'
import terrase from '../images/terrasse.jpg'
import july14 from '../images/14juillet.jpg'
import { useEffect } from 'react'

function Home(params) {

    useEffect(() => {
        // window.addEventListener()
    }, [])

    return (
        <div
            className="home-wrapper"
        >
            <div
                className="screen1"
            >
                <img src={bg1} id="bg1" />
                <div
                    className="header"
                >
                    <div
                        className="header-text-wrapper"
                    >
                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >TERRASSE EPHEMERE AVEC NAVIGATION</p>
                        <p>.</p>
                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height * 2,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >SOIREE 14 JUILLET</p>
                    </div>
                    <div
                        className="title-wrapper"
                    >
                        <h1
                            className="boreas-title"
                        >Boreas Paris</h1>
                    </div>


                </div>
                <div
                    className="arrow-wrapper"
                    onClick={() => window.scrollTo({
                        top: params.ratio.height,
                        behavior: "smooth"
                    })}
                >
                    <img src={arrow} className="arrow"
                    />
                </div>
            </div>
            <div
                className="screen2"
            >

                <div
                    className="header"
                >
                    <img src={arrow} className="arrowup" 
                    onClick={() => window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })}
                    />
                    <div
                        className="header-text-wrapper"
                    >

                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >TERRASSE EPHEMERE AVEC NAVIGATION</p>
                        <p>.</p>
                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height * 2,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >SOIREE 14 JUILLET</p>
                    </div>
                    <div
                        className="subtitle-wrapper"
                    >
                        <h2
                            className="boreas-subtitle"
                        >Terrasse éphémère</h2>

                    </div>
                    <div
                        className="product-wrapper"
                    >
                        <div
                            className="product-image-wrapper"
                        >
                            <img src={terrase} />
                        </div>
                        <div
                            className="product-text-wrapper"
                        >
                            <h3>Terrasse éphémère avec 2h30 de navigation</h3>
                            <p
                                className="product-text"
                            >
                                Mieux qu’un verre en terrasse ? Un verre sur la terrasse éphémère d’un bateau au gré des flots !

                                Le bateau BOREAS vous accueille sur son pont terrasse de 350 m² à partir de 18h30 pour un verre et une planche.

                                A partir de 20h le bateau lève l’ancre pour une croisière de 2h30. Vous pourrez siroter et vous restaurer en profitant des trésors de la plus belle ville du monde et de ses 37 ponts… Zénitude assurée
                            </p>
                            <p
                                className="product-text"
                            >
                                Embarquement quai d’Austerlitz, au pied de la Cité de la Mode et du Design, à partir de 18h30 – Départ en croisière à 20h – Retour vers 22h30.
                            </p>
                            <p
                                className="product-text"
                            >
                                Entrée 20€/personne avec une consommations incluse.

                                Bar à vin, bière et cocktail, planches maison, dans le respect des règles sanitaires.
                            </p>
                            <p
                                className="product-text"
                            >
                                Billetterie à quai – Réservations et renseignements : <a href="mailto:bateauboreas@free.fr">bateauboreas@free.fr</a> (06.28.05.54.15)
                            </p>
                            <div
                            className="reservation-div"
                            >
                                <p>Voir les dates / Réserver</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="arrow-wrapper"
                    onClick={() => window.scrollTo({
                        top: params.ratio.height * 2,
                        behavior: "smooth"
                    })}
                >
                    <img src={arrow} className="arrow"

                    />
                </div>
            </div>
            <div
                className="screen3"
            >
                <div
                    className="header"
                >
                    <img src={arrow} className="arrowup" 
                    onClick={() => window.scrollTo({
                        top: params.ratio.height,
                        behavior: "smooth"
                    })}
                    />
                    <div
                        className="header-text-wrapper"
                    >
                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >TERRASSE EPHEMERE AVEC NAVIGATION</p>
                        <p>.</p>
                        <p
                        onClick={() => window.scrollTo({
                            top: params.ratio.height * 2,
                            behavior: "smooth"
                        })}
                        style={{
                            cursor: 'pointer'
                        }}
                        >SOIREE 14 JUILLET</p>
                    </div>
                    <div
                        className="subtitle-wrapper"
                    >
                        <h2
                            className="boreas-subtitle"
                        >Soirée du 14 Juillet</h2>

                    </div>
                    <div
                        className="product-wrapper"
                    >
                        <div
                            className="product-image-wrapper"
                        >
                            <img src={july14} />
                        </div>
                        <div
                            className="product-text-wrapper"
                        >
                            <h3>Soirée du 14 Juillet</h3>
                            <p
                                className="product-text"
                            >
                                Mieux qu’un verre en terrasse ? Un verre sur la terrasse éphémère d’un bateau au gré des flots !

                                Le bateau BOREAS vous accueille sur son pont terrasse de 350 m² à partir de 18h30 pour un verre et une planche.

                                A partir de 20h le bateau lève l’ancre pour une croisière de 2h30. Vous pourrez siroter et vous restaurer en profitant des trésors de la plus belle ville du monde et de ses 37 ponts… Zénitude assurée
                            </p>
                            <p
                                className="product-text"
                            >
                                Embarquement quai d’Austerlitz, au pied de la Cité de la Mode et du Design, à partir de 18h30 – Départ en croisière à 20h – Retour vers 22h30.
                            </p>
                            <p
                                className="product-text"
                            >
                                Entrée 20€/personne avec une consommations incluse.

                                Bar à vin, bière et cocktail, planches maison, dans le respect des règles sanitaires.
                            </p>
                            <p
                                className="product-text"
                            >
                                Billetterie à quai – Réservations et renseignements : <a href="mailto:bateauboreas@free.fr">bateauboreas@free.fr</a> (06.28.05.54.15)
                            </p>
                            <div
                            className="reservation-div"
                            >
                                <p>Voir les dates / Réserver</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home